<template>
  <div class="addOrEdit">
    <div class="addOrEdit-item">
      <div class="item-header">基础信息</div>
      <div class="item-body">
        <el-form ref="topForm" :model="formData" :rules="rules" label-width="110px">
          <el-form-item prop="subjectName" label="话题名称:">
            <el-input
              v-model="formData.subjectName"
              style="width: 412px"
              placeholder="请输入话题名称"
              maxlength="15"
              size="mini"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item prop="coverUrl" label="封面图：">
            <el-upload
              class="uploader"
              action="#"
              :http-request="coverUpload"
              list-type="picture-card"
              :before-upload="beforeUpload"
              :show-file-list="false"
            >
              <div
                v-if="formData.coverUrl"
                @mouseover="coverHover = true"
                @mouseleave="coverHover = false"
              >
                <img :src="formData.coverUrl" class="avatar" />
                <div class="make" :class="coverHover && 'make--show'" @click.stop="handleRemove(1)">
                  <i class="el-icon-delete" style="color: #fff"></i>
                </div>
              </div>
              <i v-else class="el-icon-plus"></i>
              <span slot="tip" class="upload-tip"
                >仅支持jpg/png格式， 大小1M以内，建议尺寸256*256px</span
              >
            </el-upload>
          </el-form-item>

          <el-form-item prop="isDisable" label="话题状态：">
            <el-radio v-model="formData.isDisable" label="0">启用</el-radio>
            <el-radio v-model="formData.isDisable" label="1">禁用</el-radio>
          </el-form-item>

          <el-form-item prop="categoryId" label="话题分类：">
            <el-select v-model="formData.categoryId" placeholder="请选择话题分类" size="mini">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="subjectTagList" label="适关联标签：">
            <el-button size="mini" type="primary" plain @click="visible = true"
              >点击选择标签</el-button
            >
            <!-- {{ formData.subjectTagList }} -->
            <div>
              <span
                v-for="item in formData.subjectTagList"
                :key="item.id"
                class="tag"
                :style="'background:' + `${item.colour}`"
                >#{{ item.tagValue }}</span
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="addOrEdit-item">
      <div class="item-header">话题描述</div>
      <div class="item-body">
        <el-form ref="bottomForm" :model="formData" :rules="rules" label-width="110px">
          <el-form-item prop="description" label="话题描述：">
            <el-input
              v-model="formData.description"
              style="width: 570px"
              type="textarea"
              maxlength="300"
              placeholder="请输入话题描述"
              rows="6"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item prop="detailUrl" label="详情背景图：">
            <el-upload
              class="uploader-background"
              :http-request="backgroundUpload"
              list-type="picture-card"
              action="#"
              :show-file-list="false"
              :before-upload="beforebackgroundUpload"
            >
              <div
                v-if="formData.detailUrl"
                @mouseover="backgroundHover = true"
                @mouseleave="backgroundHover = false"
              >
                <img :src="formData.detailUrl" class="avatar-background" />
                <div
                  class="make"
                  :class="backgroundHover && 'make--show'"
                  @click.stop="handleRemove(2)"
                >
                  <i class="el-icon-delete" style="color: #fff"></i>
                </div>
              </div>
              <i class="el-icon-plus"></i>
              <span slot="tip" class="upload-tip"
                >仅支持jpg/png格式， 大小2M以内，建议尺寸1080*460px</span
              >
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button
              style="position: relative; bottom: -3px; height: 28px"
              type="primary"
              size="mini"
              @click="submit"
            >
              <div style="display: flex; align-items: flex-end">
                <svg
                  style="margin-right: 4px"
                  t="1663208694624"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="1385"
                  width="13"
                  height="13"
                >
                  <path
                    d="M819.823 83.694H206.991c-67.703 0-122.588 54.885-122.588 122.588v612.833c0 67.703 54.885 122.588 122.588 122.588h612.833c67.703 0 122.588-54.885 122.588-122.588V206.282c-0.001-67.703-54.885-122.588-122.589-122.588z m-124.435 63.313v241.142H331.772V147.007h363.616z m185.787 672.274c0.027 33.765-27.323 61.158-61.088 61.185H207.133c-16.389 0-31.864-6.297-43.454-17.887s-18.039-26.91-18.039-43.298v-612.94c0.061-33.923 27.57-61.395 61.493-61.41h61.327v245.294c-0.05 33.771 27.286 61.187 61.057 61.237h367.888c33.853 0 61.299-27.387 61.299-61.237V144.931h61.206c33.872 0.036 61.301 27.524 61.265 61.396V819.281z"
                    fill="#ffffff"
                    p-id="1386"
                  ></path>
                  <path
                    d="M574.817 329.936c17.483 0 31.656-14.173 31.656-31.656v-61.292c0-17.483-14.173-31.656-31.656-31.656s-31.656 14.173-31.656 31.656v61.292c0 17.483 14.173 31.656 31.656 31.656z"
                    fill="#ffffff"
                    p-id="1387"
                  ></path>
                </svg>
                提交
              </div>
            </el-button>

            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <setTagManageVue
      :visible="visible"
      :value="formData.subjectTagList"
      @ok="tagOk"
      @cancel="tagCancel"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { getTopicCategory, addTopic, getTopicDetail, updateTopic } from '@/api/friendCircle.js'
import setTagManageVue from '@/components/tag/setTagManage.vue'

export default {
  components: { setTagManageVue },

  data() {
    return {
      formData: {
        subjectName: '',
        coverUrl: '',
        isDisable: '0',
        categoryId: '',
        subjectTagList: [],
        description: '',
        detailUrl: '',
      },
      options: [],
      coverHover: false,
      backgroundHover: false,
      rules: {
        subjectName: [{ required: true, message: '请填写话题名称', trigger: 'blur' }],
        coverUrl: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        isDisable: [{ required: true, message: '请选择话题状态', trigger: 'blur' }],
        categoryId: [{ required: true, message: '请选择话题分类', trigger: 'blur' }],
        subjectTagList: [{ required: true, message: '请选择关联标签', trigger: 'blur' }],
        description: [{ required: true, message: '请填写话题描述', trigger: 'blur' }],
        detailUrl: [{ required: true, message: '请上传背景图', trigger: 'blur' }],
      },
      visible: false,
    }
  },
  computed: {
    id() {
      return this.$route.query.id || ''
    },
    ...mapGetters(['getClient']),
  },

  mounted() {
    if (this.id) {
      this.getDetail()
    }
    this.getCategory()
  },

  beforeDestroy() {
    Object.assign(this._data, this.$options.data())
  },

  methods: {
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 1

      if (!isJPG) {
        this.$message.error('上传封面图只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传封面图大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    beforebackgroundUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传详情背景图只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传详情背景图大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    coverUpload(fileObj) {
      this.ossUploadImg(fileObj.file.name, fileObj.file)
        .then((res) => {
          this.formData.coverUrl = res
        })
        .catch((e) => {})
    },

    backgroundUpload(fileObj) {
      this.ossUploadImg(fileObj.file.name, fileObj.file)
        .then((res) => {
          this.formData.detailUrl = res
        })
        .catch((e) => {})
    },

    async ossUploadImg(filename, file) {
      const date = dayjs().format('YYYY-MM-DD')
      const fileName = filename.split('.')
      const imgFilename =
        '/APPimage/' +
        date +
        '/' +
        fileName[0] +
        '-' +
        new Date().getTime() +
        (this.isPng ? '.' + fileName[1] : '.jpeg')

      const imgHeader = { 'x-oss-object-acl': 'public-read' }

      try {
        const imgResult = await this.getClient.put(imgFilename, file, {
          headers: imgHeader,
        })

        if (imgResult) {
          this.$message.success('上传成功')
          return imgResult.url
        }
      } catch (e) {
        this.$message.error('上传失败')
      }
    },

    handleRemove(type) {
      if (type === 1) {
        this.formData.coverUrl = ''
      } else {
        this.formData.detailUrl = ''
      }
    },

    reset() {
      this.$confirm('确认重置吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (this.id !== '') {
            this.getDetail()
            return
          }
          this.formData = this.$options.data().formData
        })
        .catch()
    },

    submit() {
      this.$confirm('确认立即提交吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const reqObj = {
            data: {
              ...this.formData,
            },
          }

          reqObj.data.subjectTagList = reqObj.data.subjectTagList.map((el) => el.id)

          if (this.formData.subjectId) {
            updateTopic(reqObj)
              .then((res) => {
                if (res.success) {
                  this.$message.success('提交成功')
                  this.$router.replace({ name: 'topicList' })
                }
              })
              .catch((e) => this.$message.error(e))

            return
          }

          addTopic(reqObj)
            .then((res) => {
              if (res.success) {
                this.$message.success('提交成功')
                this.$router.replace({ name: 'topicList' })
              }
            })
            .catch((e) => this.$message.error(e))
        })
        .catch()
    },

    getCategory() {
      const reqObj = {
        data: {
          isDisable: 0,
        },
      }

      getTopicCategory(reqObj).then((res) => {
        if (res.success) {
          const newArr = res.data
          this.options = newArr.map((el) => {
            return { value: el.categoryId, label: el.categoryName }
          })
        }
      })
    },

    tagOk(selectList) {
      selectList.forEach((el) => {
        el.tagValue = el.name
        delete el.name
      })

      this.formData.subjectTagList = JSON.parse(JSON.stringify(selectList))

      this.visible = false
    },
    tagCancel() {
      this.visible = false
    },
    getDetail() {
      const reqObj = {
        data: {
          subjectId: this.id,
        },
      }

      getTopicDetail(reqObj).then((res) => {
        if (res.success) {
          const { subjectId, coverUrl, detailUrl, subjectName, categoryId, description } =
            res.data[0]

          this.formData = {
            subjectId,
            coverUrl,
            detailUrl,
            subjectName,
            categoryId,
            description,
          }
          this.formData.isDisable = String(res.data[0].isDisable)
          this.formData.subjectTagList = res.data[0].subjectTagValueList
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.addOrEdit {
  background: #ffffff;
  padding-left: 20px;
  .addOrEdit-item {
    .item-header {
      padding: 12px 16px;
      position: relative;
    }

    .item-header::before {
      content: '';
      height: 18px;
      border-left: 3px solid #363f47;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      position: absolute;
      left: 0;
      top: 13px;
    }

    .item-header::after {
      content: '';
      background: #e0e0e0;
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .item-body {
      padding-left: 220px;
      padding-top: 24px;
      // padding-right: 650px;

      .tips {
        color: #9a9d9e;
      }

      .upload-tip {
        color: #9a9d9e;
        width: 200px;
        display: inline-block;
        line-height: 20px;
        margin-left: 12px;
        margin-top: 14px;
      }

      .make {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.4);
        // transition: all 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #fff;
        &--show {
          z-index: 2;
          opacity: 1;
        }
      }

      .avatar {
        width: 148px;
        height: 148px;
        display: block;

        &-background {
          width: 298px;
          height: 146px;
          display: block;
        }
      }
      .uploader {
        position: relative;

        ::v-deep .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        ::v-deep .el-upload:hover {
          border-color: #409eff;
        }

        &-background {
          position: relative;

          ::v-deep .el-upload {
            width: 300px;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          ::v-deep .el-upload:hover {
            border-color: #409eff;
          }
        }
      }

      .tag {
        border-radius: 4px;
        margin-right: 10px;
        margin-bottom: 5px;
        padding: 5px;
        white-space: nowrap;
      }
    }
  }
}
</style>
